import { XLDeployConfiguration } from '../modules/core/deployment';

export const environment = {
    production: true,
    APP_SG_CONNECT_ENDPOINT: XLDeployConfiguration.APP_SG_CONNECT_ENDPOINT,
    APP_SG_CONNECT_CLIENT_ID: XLDeployConfiguration.APP_SG_CONNECT_CLIENT_ID,
    // tslint:disable-next-line:max-line-length scopes have to be on one line
    APP_SG_CONNECT_SCOPE: XLDeployConfiguration.APP_SG_CONNECT_SCOPE,
    APP_SG_CONNECT_LEVEL: XLDeployConfiguration.APP_SG_CONNECT_LEVEL,
    Use_MutualFund_V2: XLDeployConfiguration.Use_MutualFund_V2,
    APP_SG_CONNECT_STRONG_LEVEL: XLDeployConfiguration.APP_SG_CONNECT_STRONG_LEVEL,
    GCU_FOREX_BASE_URL: XLDeployConfiguration.gcuForexBaseUrl,
    GCU_ECASH_BASE_URL: XLDeployConfiguration.gcuEcashBaseUrl,
    GCU_HABILITATION_BASE_URL: XLDeployConfiguration.gcuHabilitationBaseUrl,
    GCU_SUBSCRIPTION_BASE_URL: XLDeployConfiguration.gcuSubscriptionBaseUrl,
    GCU_FASTNET_BASE_URL: XLDeployConfiguration.gcuFastnetBaseUrl,
    GCU_CORPORATE_ACTIONS_BASE_URL: XLDeployConfiguration.gcuCorporateActionsBaseUrl,

    GCU_ESETTLEMENT_BASE_URL: XLDeployConfiguration.gcuEsettlementBaseUrl,
    STRONG_AUTH_URLS: [
        '/suite2/Modules/Asot/asotPaiementSelect.aspx',
        '/suite2/Modules/Asot/asotPaiementValid.aspx',
        '/suite2/Modules/Asot/asotPaiementValidConsult.aspx',
        '/suite2/Modules/Asot/asotPaiementValidDetail.aspx',
        '/suite2/Modules/Bse/BseSaisieSora.aspx',
        '/suite2/Modules/InstructionCash/InstructionCashConfirmation.aspx',
        '/suite2/Modules/InstructionCash/InstructionCashConfirmationPreAvis.aspx',
        '/suite2/Modules/InstructionCash/InstructionCashFileImportDetail.aspx',
        '/suite2/Modules/InstructionCash/InstructionCashFileImportResult.aspx',
        '/suite2/Modules/InstructionCash/InstructionCashGestionForm.aspx',
        '/suite2/Modules/InstructionCash/InstructionCashGestionFormDetail.aspx',
        '/suite2/Modules/InstructionCash/InstructionCashInput.aspx',
        '/suite2/Modules/InstructionCash/InstructionCashSaisie1.aspx',
        '/suite2/Modules/InstructionCash/InstructionCashSaisie2.aspx',
        '/suite2/Modules/InstructionCash/InstructionCashSaisie3.aspx',
        '/suite2/Modules/InstructionCash/InstructionCashSaisiePreAvis1.aspx',
        '/suite2/Modules/InstructionCash/InstructionCashSaisiePreAvis2.aspx',
        '/suite2/Modules/InstructionTitre/InstructionTitreCancelDetail.aspx',
        '/suite2/Modules/InstructionTitre/InstructionTitreCancelMulGrid.aspx',
        '/suite2/Modules/InstructionTitre/InstructionTitreCancelRecap.aspx',
        '/suite2/Modules/InstructionTitre/InstructionTitreCancelRechMul.aspx',
        '/suite2/Modules/InstructionTitre/InstructionTitreFileImportDetail.aspx',
        '/suite2/Modules/InstructionTitre/InstructionTitreFileImportResult.aspx',
        '/suite2/Modules/InstructionTitre/InstructionTitreGestion.aspx',
        '/suite2/Modules/InstructionTitre/InstructionTitreGestionDetail.aspx',
        '/suite2/Modules/InstructionTitre/InstructionTitreHistoTransfert.aspx',
        '/suite2/Modules/InstructionTitre/InstructionInput.aspx',
        '/suite2/Modules/InstructionTitre/InstructionTitreSaisieModel.aspx',
        '/suite2/Modules/InstructionTitre/InstructionTitreSaisieRL1.aspx',
        '/suite2/Modules/InstructionTitre/InstructionTitreSaisieRL1a.aspx',
        '/suite2/Modules/InstructionTitre/InstructionTitreSaisieRL2.aspx',
        '/suite2/Modules/InstructionTitre/InstructionTitreSaisieRL3.aspx',
        '/suite2/Modules/InstructionTitre/InstructionTitreSaisieRLDetail.aspx',
        '/suite2/Modules/InstructionTitre/InstructionTitreSaisieRLSummary.aspx',
        '/suite2/Modules/ReponseOST/reponseostsaisie.aspx',
        '/suite2/Modules/ReponseOST/ReponseOSTSaisieAnnulation.aspx',
        '/suite2/Modules/ReponseOST/ReponseOSTSaisieAnnulationChoixInstruction.aspx',
        '/suite2/Modules/ReponseOST/ReponseOSTSaisieAnnulationValid.aspx',
        '/suite2/Modules/ReponseOST/reponseostsaisiechoixcompte.aspx',
        '/suite2/Modules/ReponseOST/reponseostsaisieconsult.aspx',
        '/suite2/Modules/ReponseOST/reponseostsaisieexport.aspx',
        '/suite2/Modules/ReponseOST/reponseostsaisierecap.aspx',
        '/suite2/Modules/ReponseOST/reponseostsaisierechmul.aspx',
        '/suite2/Modules/ReponseOST/reponseostsaisierechmulsol.aspx',
        '/suite2/Modules/ReponseOST/reponseostsaisievalid.aspx',
        '/suite2/Modules/ReponseOST/reponseostsaisievalidation.aspx',
        '/Upload/InstrCash/InstructionCashfileImport.aspx',
        '/Upload/InstrRL/InstructionTitreFileImport.aspx',
        '/Upload/InstrRL/InstructionTitreFileImportRL.aspx',
        '/Upload/InstrRL/InstructionTitreTransfertFichierRL.aspx',
        '/Upload/PjCash/InstructionCashPj.aspx',
        '/Asp/MultiLang/ANG/Asp/LRSORAImpFUp_Fichier.asp',
        '/Asp/MultiLang/ANG/Asp/LRSORAImpFUp_Liste.asp',
        '/Asp/MultiLang/ANG/Asp/LRSORAImpFUp_Saisie.asp',
        '/Asp/MultiLang/ANG/Asp/LRSORAImpFUp_View.asp',
        '/Asp/MultiLang/FRA/Asp/LRSORAImpFUp_Fichier.asp',
        '/Asp/MultiLang/FRA/Asp/LRSORAImpFUp_Liste.asp',
        '/Asp/MultiLang/FRA/Asp/LRSORAImpFUp_Saisie.asp',
        '/Asp/MultiLang/FRA/Asp/LRSORAImpFUp_View.asp',
        '/suite2/Modules/BANCS_CA/BancsVoluntaryCA.aspx',
        '/suite2/Modules/BANCS_CA/BancsVoluntaryCaAccntList.aspx',
        '/suite2/Modules/BANCS_CA/BancsVoluntaryCaInputForm.aspx',
        '/suite2/Modules/BANCS_CA/BancsVoluntaryCaInstructionList.aspx',
        '/suite2/Modules/BANCS_CA/BancsVoluntaryCaInstructionManagement.aspx',
        '/suite2/Modules/BANCS_CA/BancsVoluntaryCaInstrConfirmation.aspx',
    ],
    PIWIK_ID: XLDeployConfiguration.piwikId,
};
